<template>
  <div>
    <div class="pdp-box buyBox-orderOnline">
      <div class="buyBox-orderOnline__headline">
        <h5>
          {{ $tv('orderOnline.headline', 'buyBoxAnonymous') }}
        </h5>
      </div>
      <div class="buyBox-orderOnline__text">
        {{ $tv('orderOnline.text', 'buyBoxAnonymous') }}
      </div>
      <div class="buyBox-orderOnline__button">
        <ReactiveButton
          @click="handleClick"
        >
          {{ $tv('orderOnline.buttonText', 'buyBoxAnonymous') }}
        </ReactiveButton>
      </div>
    </div>
    <PopupPartnerSelection
      :show="showPartnerSelection"
      :product="props.product"
      :partners="props.partners"
      @close-pop-up="showPartnerSelection = false"
    />
  </div>
</template>

<script setup lang="ts">

import type {PartnerHubPartner} from '~/types/partner-hub'
import {useGtm} from '@gtm-support/vue-gtm'

const props = defineProps({
  product: {
    type: Object,
    required: true,
    default() {
      return {}
    }
  },
  partners: {
    type: Array<PartnerHubPartner>,
    required: true,
    default() {
      return []
    }
  }
})

let showPartnerSelection = ref<boolean>(false)

const handleClick = () => {
  showPartnerSelection.value = true
  trackSelectPartnerClick()
}

const trackSelectPartnerClick = () => {
  useGtm()?.trackEvent({
    event: 'partnerh_selectpartner',
    category: 'partner_hub',
    ecommerce: {
      sku: props.product.sku
    }
  })
}

</script>

<style scoped lang="scss">
.pdp-box {
  box-shadow: $default-figma-boxshadow;
  padding: 1rem;
  background-color: $color__white;
}

.buyBox-orderOnline {
  margin-bottom: 1.25rem;

  &__headline {
    margin-bottom: 1rem;

    h5 {
      margin: 0;
    }
  }

  &__text {
    font-weight: normal;
    margin-bottom: 1rem;
  }

  &__button {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
