import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  class: "icon--svg icon"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M10.5808 16.2538L17.3038 9.53075L16.25 8.47693L10.5808 14.1462L7.73077 11.2962L6.67695 12.35L10.5808 16.2538ZM12.0017 21.5C10.6877 21.5 9.45271 21.2506 8.29657 20.752C7.14042 20.2533 6.13474 19.5765 5.27952 18.7217C4.42429 17.8669 3.74723 16.8616 3.24835 15.706C2.74946 14.5504 2.50002 13.3156 2.50002 12.0017C2.50002 10.6877 2.74936 9.45268 3.24802 8.29655C3.74669 7.1404 4.42344 6.13472 5.27827 5.2795C6.13312 4.42427 7.13836 3.74721 8.294 3.24833C9.44962 2.74944 10.6844 2.5 11.9983 2.5C13.3123 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8653 4.42342 18.7205 5.27825C19.5757 6.1331 20.2528 7.13834 20.7516 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5766 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0017 21.5Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }