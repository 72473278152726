<template>
  <PopupBase
    class="popup-rfq-form"
    :show="props.show"
    close-on-outside-click
    @close-pop-up="emit('close-pop-up')"
  >
    <div class="popup-rfq-form__header">
      <h5>{{ i18n.tv('rfqPopupFormHeadline', 'partnerHub') }}</h5>
    </div>
    <div class="popup-rfq-form__body">
      <div class="popup-rfq-form__body__info">
        <div class="selected-product">
          <label class="rfq-form-label">{{ i18n.tv('rfqPopupFormLabelProduct', 'partnerHub') }}</label>
          <span class="selected-product-text">{{ productText }}</span>
        </div>
        <div class="selected-partner">
          <label class="rfq-form-label">{{ i18n.tv('rfqPopupFormLabelPartner', 'partnerHub') }}</label>
          <span class="selected-partner-name-address">{{ partnerTextNameAndAddress }}</span>
          <span class="selected-partner-zip-city">{{ partnerTextZipAndCity }}</span>
        </div>
      </div>
      <div class="popup-rfq-form__body__form">
        <ValidationForm
          ref="rfqValidationForm"
          :id="formId"
          use-model-values-for-validation
          :get-form="getForm"
          @success="submitForm"
        >
          <div class="rfq-form-row-1">
            <div class="rfq-form-column-left">
              <label class="rfq-form-label">{{ i18n.tv('rfqPopupFormLabelQuantity', 'partnerHub') }}</label>
              <NumericInput
                ref="validationFormQuantity"
                class="rfq-form-input-small"
                v-model="formData.quantity"
                :rules="[requiredNumberValidationRule(i18n.tv('rfqPopupFormLabelQuantity', 'partnerHub'))]"
              />
            </div>
            <div>
              <label class="rfq-form-label">{{ i18n.tv('rfqPopupFormLabelDate', 'partnerHub') }}</label>
              <div @click="showDatePicker = true">
                <DatePicker
                  ref="validationFormDeliveryDate"
                  class="rfq-form-input-small rfq-form-input-date-picker"
                  v-model="formData.deliveryDate"
                  :custom-date-format="dateFormat"
                  :min="getNextDate()"
                  :max="getMaxDate()"
                  :show="showDatePicker"
                  @toggleCalender="showDatePicker = true"
                  @focus="handleDateFocus"
                  @blur="handleDateBlur"
                  @change="showDatePicker = false"
                />
              </div>
            </div>
          </div>
          <div class="rfq-form-row-2">
            <div class="rfq-form-column-left">
              <label class="rfq-form-label">{{ i18n.tv('rfqPopupFormLabelCompany', 'partnerHub') }}</label>
              <InputText
                ref="validationFormCompanyName"
                class="rfq-form-input-small"
                v-model="formData.company"
                name="companyName"
                :rules="[requiredValidationRule(i18n.tv('rfqPopupFormLabelCompany', 'partnerHub'))]"
                :placeholder="i18n.tv('rfqPopupFormPlaceholderCompany', 'partnerHub')"
              />
            </div>
            <div>
              <label class="rfq-form-label">{{ i18n.tv('rfqPopupFormLabelContact', 'partnerHub') }}</label>
              <InputText
                ref="validationFormContact"
                class="rfq-form-input-small"
                v-model="formData.contact"
                name="contact"
                :rules="[requiredValidationRule(i18n.tv('rfqPopupFormLabelContact', 'partnerHub'))]"
                :placeholder="i18n.tv('rfqPopupFormPlaceholderContact', 'partnerHub')"
              />
            </div>
          </div>
          <div class="rfq-form-row-3">
            <div class="rfq-form-column-left">
              <label class="rfq-form-label">{{ i18n.tv('rfqPopupFormLabelEmail', 'partnerHub') }}</label>
              <InputText
                ref="validationFormEmail"
                class="rfq-form-input-small"
                v-model="formData.email"
                name="email"
                :rules="[emailValidationRule(i18n.tv('rfqPopupFormLabelEmail', 'partnerHub'))]"
                :placeholder="'john.doe@yourcompany.com'"
              />
            </div>
            <div>
              <label class="rfq-form-label">{{ i18n.tv('rfqPopupFormLabelPhone', 'partnerHub') }}</label>
              <InputText
                ref="validationFormPhone"
                class="rfq-form-input-small"
                v-model="formData.phone"
                name="phone"
                :rules="[requiredValidationRule(i18n.tv('rfqPopupFormLabelPhone', 'partnerHub'))]"
                :placeholder="'+1 123 456 789'"
              />
            </div>
          </div>
          <div class="rfq-form-row-4">
            <label class="rfq-form-label">{{ i18n.tv('rfqPopupFormLabelComments', 'partnerHub') }}</label>
            <InputText
              ref="validationFormComment"
              class="rfq-form-input-big"
              multiline
              v-model="formData.comments"
              name="comments"
              :rows="4"
              @input-value="formData.comments = $event"
            />
          </div>
          <div class="rfq-form-row-5">
            <Checkbox
              ref="validationFormPrivacyPolicy"
              class="rfq-form-checkbox-1"
              v-model="formData.privacyPolicy"
              name="privacyPolicy"
              :value="formData.privacyPolicy"
              :label="privacyPolicyLabel"
              :rules="[requiredCheckboxValidationRule('Field')]"
            />
            <Checkbox
              ref="validationFormAuthorizeInquiry"
              class="rfq-form-checkbox-2"
              v-model="formData.authorizeInquiry"
              :value="formData.authorizeInquiry"
              :label="i18n.tv('rfqPopupFormLabelAuthorizeInquiry', 'partnerHub')"
              :rules="[requiredCheckboxValidationRule('Field')]"
            />
            <div class="rfq-form-legal-statement">
              <Markdown :text="i18n.tv('rfqPopupFormLegalStatement', 'partnerHub')" />
            </div>
          </div>
          <div class="rfq-form-row-6">
            <ReactiveButton
              type="submit"
              button-text="Submit"
              :is-primary-button="false"
              :is-loading="isLoading"
            />
          </div>
          <input
            hidden
            name="firstName"
            :value="splitName.firstName"
          >
          <input
            hidden
            name="lastName"
            :value="splitName.lastName"
          >
          <input
            hidden
            name="description"
            :value="formCaptureDescription"
          >
          <input
            hidden
            name="subject"
            value="EASY PartnerHub RFQ"
          >
          <input
            hidden
            name="initialIndustry"
            value="419260001"
          >
          <input
            hidden
            name="marketingLanguage"
            :value="formCaptureMarketingLanguage"
          >
          <input
            hidden
            name="country"
            :value="formCaptureCountry"
          >
        </ValidationForm>
      </div>
    </div>
  </PopupBase>
</template>

<script setup lang="ts">

import ValidationForm from '~/components/input-components/ValidationForm.vue'
import NumericInput from '~/components/input-components/NumericInput.vue'
import DatePicker from '~/components/input-components/DatePicker.vue'
import InputText from '~/components/input-components/InputText.vue'
import Checkbox from '~/components/input-components/Checkbox.vue'
import useI18nExtended from '~/composables/usei18nExtended'
import {getMaxDate, getNextDate, splitFullName} from 'assets/js/utils'
import {
  emailValidationRule,
  requiredCheckboxValidationRule, requiredNumberValidationRule,
  requiredValidationRule
} from 'assets/js/validations/common_validation_rules'
import {useMailService} from '~/composables/mail_service'
import type {RFQRequestBody} from '~/composables/mail_service'
import {getEComText} from 'assets/js/utils/product'
import {API_INDICATORS} from 'assets/js/constants'
import {REGION_KEYS} from 'assets/js/constants.js'
import {useGtm} from '@gtm-support/vue-gtm'
import {usePartnerHubHelper} from '~/composables/partner_hub_helper'
import {getCurrentInstance} from 'vue'

const app = useNuxtApp()
const i18n = useI18nExtended()
const {postRFQRequest} = useMailService()
const {loadCRMFormCapture, getLanguageId} = usePartnerHubHelper()
const emit = defineEmits(['close-pop-up'])

onMounted(() => {
  loadCRMFormCapture(formId.value)
})

const props = defineProps({
  show: {
    type: Boolean,
    default() {
      return false
    }
  },
  product: {
    type: Object,
    required: true,
    default() {
      return {}
    }
  },
  partner: {
    type: Object,
    required: true,
    default() {
      return {}
    }
  }
})

const formData = ref({
  quantity: null,
  deliveryDate: getNextDate(),
  company: '',
  contact: '',
  email: '',
  phone: '',
  comments: '',
  privacyPolicy: false,
  authorizeInquiry: false
})

const {uid} = getCurrentInstance()
const formId = ref('rfqValidationFormId_' + uid)
const datePickerBlurTimeout = ref()
let showDatePicker = ref<boolean>(false)
let isLoading = ref<boolean>(false)

const productText = computed(() => {
  return getEComText(i18n.tv, props.product)
})

const partnerTextNameAndAddress = computed(() => {
  return `${props.partner.supplier} - ${props.partner.street}`
})

const dateFormat = computed(() => {
  return app.$globalization.__currentRegion === REGION_KEYS.NA ? 'MM/dd/yyyy' : 'dd.MM.yyyy'
})

const partnerTextZipAndCity = computed(() => {
  return `${props.partner.zip} ${props.partner.city} ${props.partner.addressRegion ? `(${props.partner.addressRegion})` : ''}`
})

const splitName = computed(() => {
  return splitFullName(formData.value.contact ?? '')
})

const formCaptureDescription = computed(() => {
  const formattedDate = app.$formatDate(formData.value.deliveryDate, dateFormat.value)
  return JSON.stringify({
    SelectedPartner: partnerTextNameAndAddress.value + ', ' + partnerTextZipAndCity.value,
    SelectedProduct: productText.value,
    Quantity: formData.value.quantity,
    DesiredDeliveryDate: formattedDate
  })
})
const formCaptureMarketingLanguage = computed(() => {
  return JSON.stringify({
    Id: getLanguageId(i18n.locale.value),
    LogicalName: 'zz_language'
  })
})
const formCaptureCountry = computed(() => {
  return JSON.stringify({
    Id: 'e073a636-08bb-e611-80f4-00155d85c140',
    LogicalName: 'zz_country'
  })
})

const privacyPolicyLabel = computed(() => {
  let labelText = i18n.tv('rfqPopupFormLabelPrivacyPolicy', 'partnerHub')
  // Escape the entire input string to prevent XSS
  const div = document.createElement('div')
  div.textContent = labelText
  let escapedText = div.innerHTML

  // Allow <a> tags by replacing the escaped versions back
  escapedText = escapedText.replace(/&lt;a\s/g, '<a ')
    .replace(/&lt;\/a&gt;/g, '</a>')
    .replace(/&gt;/g, '>')

  // Parse the sanitized string back into a DOM structure
  const parsedHTML = new DOMParser().parseFromString(escapedText, 'text/html')

  // Allowed attributes for <a> tag
  const allowedAttributes = ['href', 'target']
  parsedHTML.querySelectorAll('a').forEach(anchor => {
    [...anchor.attributes].forEach(attr => {
      if (!allowedAttributes.includes(attr.name)) {
        anchor.removeAttribute(attr.name)
      }
    })
  })

  return parsedHTML.body.innerHTML
})

const getForm = () => {
  return formData.value
}
const handleDateBlur = () => {
  datePickerBlurTimeout.value = setTimeout(() => {
    showDatePicker.value = false
  }, 50)
}
const handleDateFocus = () => {
  if (datePickerBlurTimeout.value) {
    clearTimeout(datePickerBlurTimeout.value)
  }
}

const trackRFQRequest = () => {
  useGtm()?.trackEvent({
    event: 'partnerh_rfq',
    category: 'partner_hub',
    ecommerce: {
      sku: props.product.sku,
      distributor: props.partner.supplier
    }
  })
}

const getProductUrl = () => {
  const productsPath = app.vueApp.localePath('products-product')
  const domain = window.location.origin
  return `${domain}${productsPath}`
}

const submitForm = () => {
  isLoading.value = true
  const splitName = splitFullName(formData.value.contact)
  const formattedDate = app.$formatDate(formData.value.deliveryDate, dateFormat.value)

  const requestBody: RFQRequestBody = {
    indicator: API_INDICATORS.PARTNER_HUB,
    customerUuid: null,
    userUuid: null,
    email: formData.value.email,
    firstName: splitName.firstName,
    lastName: splitName.lastName,
    company: formData.value.company,
    phone: formData.value.phone,
    message: formData.value.comments,
    payload: {
      salesText: productText.value,
      sku: props.product.sku,
      supplierName: props.partner.supplier,
      supplierMail: props.partner.mail,
      quantity: formData.value.quantity ?? 0,
      wishedDeliveryDate: formattedDate,
      productUrl: getProductUrl()
    }
  }

  postRFQRequest(requestBody).then(() => {
    app.$toast.success(i18n.tv('rfqPopupFormToastSuccess', 'partnerHub'))
    isLoading.value = false
    emit('close-pop-up')
    trackRFQRequest()
  }).catch(() => {
    app.$toast.error(i18n.tv('rfqPopupFormToastError', 'partnerHub'))
    isLoading.value = false
  })
}

</script>

<style lang="scss">
.popup-rfq-form {
  .rfq-form-label {
    font-weight: 300;
    font-size: 0.875rem;
    margin-bottom: 0.313rem;
  }

  &__header {
    h5 {
      margin: -.5rem 0 1rem;
    }
  }

  &__body {
    max-width: 30.813rem;

    ::placeholder {
      opacity: 0.5;
    }

    &__info {
      .selected-product {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .selected-partner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1rem;
      }
    }

    &__form {
      .rfq-form-input-small {
        width: 14.594rem;
        margin-top: 0.375rem;
      }

      .rfq-form-input-big {
        padding-top: 0.375rem;

        textarea {
          resize: none;
        }
      }

      .rfq-form-column-left {
        margin-right: 1.5rem;
      }

      .rfq-form-row-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: .75rem;

        span {
          min-height: 0;
        }

        .rfq-form-input-date-picker {
          border: .0625rem solid $color--input__border;

          .k-input-inner {
            border-top: none;
            border-right: .0625rem solid $color--input__border;
            border-bottom: none;
            border-left: none;
            font-weight: 520;
          }

          .k-button.k-button-md.k-icon-button.k-button-solid.k-button-solid-base.k-input-button {
            margin-left: 0;
          }
        }
      }

      .rfq-form-row-2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.5rem;

        span {
          min-height: 0;
        }
      }

      .rfq-form-row-3 {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 1.5rem;

        span {
          min-height: 0;
        }
      }

      .rfq-form-row-4 {
        margin-top: 1.5rem;
      }

      .rfq-form-row-5 {
        margin-top: 1.5rem;

        .rfq-form-checkbox-1 {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;

          span {
            min-height: 0;
          }
        }

        .rfq-form-checkbox-2 {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          margin-top: 1rem;

          span {
            min-height: 0;
          }
        }

        label {
          font-weight: 300;
          font-size: 0.875rem;
          cursor: default;
        }
      }

      .rfq-form-row-6 {
        display: flex;
        justify-content: flex-end;
        margin-bottom: -.5rem;
        margin-top: 2rem;
      }
    }
  }
}
</style>
