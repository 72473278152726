<template>
  <div>
    <PopupBase
      :show="show"
      :size="popupSize"
      class="popup-partner-selection"
      close-on-outside-click
      :class="{ 'max-3-items': purchasablePartners.length <= 3 && requestablePartners.length <= 3 }"
      @close-pop-up="$emit('closePopUp')"
    >
      <h2
        class="partner-selection-header"
        v-tv:partner-hub="'selectFreudenbergPartner'"
      />
      <span
        class="partner-product-text"
        v-text="productName"
      />
      <PartnerSelection
        :purchasable-partners="purchasablePartners"
        :requestable-partners="requestablePartners"
        @partnerRFQ="handlePartnerRequest"
        @goToPartner="handleGoToPartner"
      />
    </PopupBase>
    <div class="rfq-popup-inside-partner-selection">
      <PopupRFQForm
        :show="showRFQForm"
        :product="props.product"
        :partner="selectedPartner"
        @close-pop-up="showRFQForm = false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import PopupBase from './PopupBase.vue'
import {POPUP, PARTNER_SINGLE_STATUS} from '~/assets/js/constants'
import type {PartnerHubPartner} from '~/types/partner-hub.d'
import PartnerSelection from '~/components/partner-hub/PartnerSelection.vue'
import {getEComText} from 'assets/js/utils/product'
import useI18nExtended from '~/composables/usei18nExtended'
import {useGtm} from '@gtm-support/vue-gtm'

const i18n = useI18nExtended()
const popupSize = POPUP.Size.large
defineEmits(['partnerRFQ', 'goToPartner', 'closePopUp'])

const props = defineProps({
  partners: {
    type: Array<PartnerHubPartner>,
    required: true,
    default() {
      return []
    }
  },
  product: {
    type: Object,
    required: true,
    default() {
      return {}
    }
  },
  show: {
    type: Boolean,
    required: true
  }
})

let selectedPartner = ref<object>({})
let showRFQForm = ref<boolean>(false)

const purchasablePartners = computed(() => {
  return props.partners.filter(partner =>
    partner.state === PARTNER_SINGLE_STATUS.Purchase ||
    partner.state === PARTNER_SINGLE_STATUS.PurchaseFactoryOrder
  )
})

const requestablePartners = computed(() => {
  return props.partners.filter(partner =>
    !(partner.state === PARTNER_SINGLE_STATUS.Purchase ||
    partner.state === PARTNER_SINGLE_STATUS.PurchaseFactoryOrder)
  )
})

const productName = computed(() => {
  return getEComText(i18n.tv, props.product)
})

const handlePartnerRequest = (partner: PartnerHubPartner) => {
  selectedPartner.value = partner
  showRFQForm.value = true
}

const handleGoToPartner = (partner: PartnerHubPartner) => {
  let url = partner.url
  const hasHttp = /^https?:\/\//i
  if (!hasHttp.test(url)) {
    url = 'https://' + url
  }

  window.open(url, '_blank')
  trackGoToPartner(partner)
}

const trackGoToPartner = (partner: PartnerHubPartner) => {
  useGtm()?.trackEvent({
    event: 'partnerh_buy',
    category: 'partner_hub',
    ecommerce: {
      sku: props.product.sku,
      distributor: partner.supplier
    }
  })
}
</script>

<style lang="scss">
.rfq-popup-inside-partner-selection {
  .popup--backdrop {
    z-index: 500 !important;
  }

  .popup {
    z-index: 501 !important;
  }

  .popup__wrapper {
    margin-top: 10rem;
  }
}

.popup-partner-selection {
  width: 100%;

  &.max-3-items {
    .popup__wrapper {
      min-width: 0;
      max-width: 42rem;
    }
  }

  .popup__wrapper {
    min-width: 67.5rem;
    max-width: 75rem;

    @include breakpoint-down(43rem) {
      width: 100%;
    }

    .popup__content {
      padding: 1rem;
      overflow-x: hidden;
    }
  }

  .partner-selection-header {
    margin-top: 0;
    font-size: 1.125rem;
    color: $color__primary;
  }

  .partner-product-text {
    display: block;
    font-size: .875rem;
    margin-bottom: 1rem;
  }

  @include breakpoint-down($xl) {
    .popup__wrapper {
      min-width: 0;
    }
  }
}
</style>
