import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  class: "icon--svg icon"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M8.58075 14.1438L15.3038 7.42077L14.25 6.36694L8.58075 12.0362L5.73075 9.18617L4.67693 10.24L8.58075 14.1438ZM10.0016 19.39C8.68772 19.39 7.45268 19.1406 6.29655 18.642C5.1404 18.1433 4.13472 17.4666 3.2795 16.6117C2.42427 15.7569 1.74721 14.7516 1.24833 13.596C0.749442 12.4404 0.5 11.2056 0.5 9.89167C0.5 8.57773 0.749334 7.3427 1.248 6.18656C1.74667 5.03042 2.42342 4.02473 3.27825 3.16952C4.1331 2.31428 5.13834 1.63722 6.29398 1.13834C7.44959 0.639457 8.68437 0.390015 9.9983 0.390015C11.3122 0.390015 12.5473 0.639349 13.7034 1.13802C14.8596 1.63668 15.8652 2.31343 16.7205 3.16827C17.5757 4.02312 18.2527 5.02836 18.7516 6.18399C19.2505 7.33961 19.5 8.57438 19.5 9.88832C19.5 11.2022 19.2506 12.4373 18.752 13.5934C18.2533 14.7496 17.5765 15.7552 16.7217 16.6105C15.8669 17.4657 14.8616 18.1428 13.706 18.6416C12.5504 19.1405 11.3156 19.39 10.0016 19.39Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }